import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import CreateRecentPosts from './CreateRecentPosts'

export default props => (
  <StaticQuery
    query={graphql`
      query GetAllPostsMeta {
        allWordpressPost(sort: { fields: date, order: DESC }) {
          edges {
            node {
              lang
              id
              wordpress_id
              status
              link
              excerpt
              path
              featured_media {
                source_url
              }
              categories {
                name
                slug
              }

              acf {
                title
                post_image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 2560, quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <aside className="section constrained">
        <h2>More articles</h2>
        <ul className="more_our_work blog">
          <CreateRecentPosts
            data={data}
            currentID={props.currentPostID}
            lang={props.lang}
          />
          <li className="all">
            <Link to="/blog">
              <div>
                See all
                <span className="icon-arrow-right" />
              </div>
            </Link>
          </li>
        </ul>
      </aside>
    )}
  />
)
