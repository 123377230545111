import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { Link } from 'gatsby'

export default function CreateRecentPosts({ lang, data, currentID }) {
  const { allWordpressPost: posts } = data
  let total = 0

  // loop through last 5 posts
  return posts.edges
    .filter(i => i.node.lang === lang)
    .map((post, i) => {
      const {
        excerpt,
        link,
        status,
        wordpress_id,
        featured_media,
        acf,
        path,
        categories,
      } = post.node

      if (status === 'publish' && total < 5 && post.node.id !== currentID) {
        // check if it is a regular post or a job post
        total += 1

        return (
          <li key={post.node.id}>
            <Link to={post.node.path}>
              <div
                className="single-other-reference"
                style={{
                  backgroundImage: `url(${post.node.acf.post_image.localFile.childImageSharp.fluid.src})`,
                }}
              />
              {post.node.acf.title}
            </Link>
          </li>
        )
      }
    })
}
