import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import FlexibleContent from '../components/FlexibleContent'
import LatestPosts from '../components/RecentPosts'
import BackgroundPageImage from '../components/BackgroundPageImage'

export const PostTemplate = ({ lang, currentPostID, title, acf, date }) => {
  const displayDefaultDatePosition = <p>{date}</p>
  const displayDefaultPostTitle = <h1>{title}</h1>
  const displayLatestPosts = (
    <LatestPosts currentPostID={currentPostID} lang={lang} />
  )

  return (
    <article className="post">
      <header className="narrow page-header post">
        {displayDefaultPostTitle}
      </header>
      <FlexibleContent data={acf.flexible_content_post} />
      {displayLatestPosts}
    </article>
  )
}

PostTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

const Post = ({ data }) => {
  const { wordpressPost: post } = data
  const { title, categories, lang, translations, date, acf } = post
  const { image, position } = acf
  const jobPost = false

  const imageBackground =
    typeof image !== 'undefined' && image !== null ? image : false
  const imagePosition =
    typeof position !== 'undefined' && position !== null ? position : false
  const displayBackgroundImage =
    imageBackground && imagePosition ? (
      <BackgroundPageImage image={imageBackground} position={imagePosition} />
    ) : (
      ''
    )

  return (
    <Layout lang={lang} translations={translations}>
      <Helmet title={`${title} | Don & Branco`} />
      {displayBackgroundImage}
      <PostTemplate
        lang={lang}
        currentPostID={post.id}
        title={title}
        acf={acf}
        date={date}
        jobPost={jobPost}
      />
    </Layout>
  )
}

Post.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Post

export const pageQuery = graphql`
  query GetPostById($id: String!) {
    wordpressPost(id: { eq: $id }) {
      id
      status
      date(formatString: "DD.MM.YYYY")
      title
      path
      lang
      translations {
        en
        fi
      }
      categories {
        slug
      }
      acf {
        position
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2560, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        flexible_content_post {
          __typename
          ... on Node {
            ... on WordPressAcf_wysiwyg {
              class_name
              wysiwyg
            }

            ... on WordPressAcf_two_images {
              class_name
              image_sizes
              first_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2560, quality: 100) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }

              second_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2560, quality: 100) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }

            ... on WordPressAcf_text_in_columns {
              class_name
              columns {
                column_title
                column_title_link
                lines {
                  text
                }
              }
            }

            ... on WordPressAcf_image {
              class_name
              full_width_image
              image_size
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2560, quality: 100) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }

            ... on WordPressAcf_links_to_references {
              class_name
              references_page_link
              references {
                link
                link_text
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 2560, quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
            }

            ... on WordPressAcf_quote {
              quote
              quoted_person
            }

            ... on WordPressAcf_writer_block {
              writer {
                email
                first_name
                job_title
                last_name
                self_congratulatory
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 2560, quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
            }

            ... on WordPressAcf_video {
              class_name
              video_file {
                localFile {
                  url
                }
              }
              full_width_video
            }

            ... on WordPressAcf_video_and_image {
              class_name
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2560, quality: 100) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
              video {
                localFile {
                  url
                }
              }
              alignment
            }

            ... on WordPressAcf_staff {
              class_name
              peeps {
                title
                name
                tel
                email
              }
            }

            ... on WordPressAcf_video_embed {
              class_name
              url
            }
          }
        }
      }
    }
  }
`
